import React from 'react';
import { FourOhFour } from 'gatsby-theme-carbon';

const links = [
  { href: '/', text: 'Главная страница' },
  { href: '/content/for-suppliers', text: 'Для поставщиков' },
];

const Custom404 = () => <FourOhFour links={links} />;

export default Custom404;
